import { Component, OnInit } from '@angular/core';
import { FirebaseApiService } from '../../../src/app/firebaseapi.service'
import { ContactsUs } from '../../../src/app/contacts-us.model';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  contactsUs:any = {};
  contactUsForm: FormGroup;
  invalidcontactUs: boolean = false;
  successMsg: String = ""
  constructor(private formBuilder: FormBuilder, private firebaseApiService: FirebaseApiService) { }

  ngOnInit() {
    this.contactUsForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      name: ['', Validators.required],
      mobile: ['', Validators.required],
      subject: [''],
      message: ['', Validators.required]
    });
  }

  createContactUs(){
    if (this.contactUsForm.invalid) {
      return;
    }
    this.invalidcontactUs = false;
    this.contactsUs['name'] = this.contactUsForm.controls.name.value;
    this.contactsUs['email'] = this.contactUsForm.controls.email.value;
    this.contactsUs['mobile'] = this.contactUsForm.controls.mobile.value;
    this.contactsUs['subject'] = this.contactUsForm.controls.subject.value;
    this.contactsUs['message'] = this.contactUsForm.controls.message.value;
    console.log("Contacts ================= ", this.contactsUs)
    this.firebaseApiService.createPolicy(this.contactsUs)
    .then(res =>{
      console.log("Inserted Successfully........", res)
      this.ngOnInit();
      this.contactsUs = {}
      this.successMsg = "Message has been submitted successfully";
    });
  }
}
