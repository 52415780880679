import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ContactsUs } from './contacts-us.model';

@Injectable({
  providedIn: 'root'
})
export class FirebaseApiService {

  constructor(private firestore: AngularFirestore) { }

  createPolicy(contactsUs: ContactsUs){
    return this.firestore.collection('contacts_us').add(contactsUs);
  }
}
